import { gql } from '@apollo/client';

export const GET_INTRODUCTION_PAGE = gql`
  query getIntroPage($pageNumber: Int!) {
    assessmentIntroduction(pageNumber: $pageNumber) {
      mainHeading
      mainHeadingContent
      source
      otherInformation {
        sourceLinks
      }
      questionsList {
        questions
      }
      subHeadingContentList {
        subHeadingContent {
          heading
          content
        }
      }
    }
  }
`

export const GET_USER_INFORMATION = gql`
  query getUserInformation {
    user {
      firstName
      lastName
      email
      phoneNumber
      primaryCountryCode
      alternateCountryCode
      altPhoneNumber
      pcPronouns
      country {
        name
        value
      }
      address1
      address2
      city
      state
      zipcode
      region {
        name
        value
      }
      otherLanguage
      usCitizen
      informalReferral
      attorney
      reviewLanguages
      resumeName
      training
      foreignLicense
      biography
      noPriorExperience
      languages {
        name
        value
      }
      degrees {
        id
        degreeName
        university
        major
        honors
        dateCompleted
      }

      licenses {
        id
        state
        yearPassed
        verified
        attorneyId
      }

      projects {
        id
        durationMonths
        responsibilities
        practiceArea {
          name
          value
        }
        industry {
          name
          value
        }
        softwareProduct {
          name
          value
        }
        languages {
          name
          value
        }
      }
    }
  }
`

export const PROFILE_COMPLETION_STATUS = gql`
  query profileCompletionStatus {
    profileCompletionStatus {
        basicInformation
        education
        biography
        resume
        licensure
        documentReviewExperience
        realAssessment
        profileStatus
    }
  }
`

export const COUNTRY_LIST = gql`
  query countryList {
    countryList {
      name
      value
    }
  }
`

export const REGION_LIST = gql`
  query regionList($countryId: String!) {
    regionList(countryId: $countryId) {
      name
      value
    }
  }
`

export const LANGUAGE_LIST = gql`
  query languageList {
    languageList {
      name
      value
    }
  }
`

export const PRACTICE_AREA_LIST = gql`
  query practiceAreasList {
    practiceAreasList {
      name
      value
    }
  }
`

export const INDUSTRY_LIST = gql`
  query industryList {
    industryList {
      name
      value
    }
  }
`

export const SOFTWARE_PRODUCT_LIST = gql`
  query softwareProductList {
    softwareProductList {
      name
      value
    }
  }
`

export const YEAR_LIST = gql`
  query softwareProductList {
    yearList {
      name
      value
    }
  }
`

export const RESPONSIBILITY_LIST = gql`
  query responsibilityList {
    responsibilityList {
      name
      value
    }
  }
`

export const STATE_LIST = gql`
  query stateList($countryName: String!) {
    stateList(countryName: $countryName) {
      name
      value
    }
  }
`

export const TERMS_AND_CONDITIONS = gql`
  query termsAndConditions {
    termsAndConditions {
      content
      versionId
    }
  }
`

export const SYSTEM_MESSAGES = gql`
  query systemMessages {
    systemMessages {
      content
      id
    }
  }
`