import { useState } from 'react'
import Icon from '../../../../Icon'
import classes from './ExpandableSection.module.scss'


interface ExpandableSectionProps {
  heading: string
  children?: React.ReactNode
  expand?: boolean
  onCloseHandler: () => void
  headingPrivacy?: 'allow' | 'mask' | 'hidden' | 'mask-user-input'
}

const ExpandableSection = ({heading, children, onCloseHandler, expand, headingPrivacy="allow"}: ExpandableSectionProps) => {
  const [expanded, setExpanded] = useState(expand || false)

  return (
    <div className={classes.expandable_section}>
      <div className={classes.heading} data-dd-action-name='Expandable section'>
        <div className={classes.heading_content} data-dd-action-name='Expandable section'>
          <button type='button' className={expanded ? classes.arrow_up : classes.arrow_right} onClick={() => setExpanded((value) => !value)} data-dd-action-name='Expandable section dropdown'>
            <Icon name='sortDown' size='18' color='#025A66' transform={true}/>
          </button>
          <div className={classes.title} data-dd-privacy={headingPrivacy} data-dd-action-name='Expandable section'>
            {heading}
          </div>
        </div>
        <div className={classes.icons}>
          <button type='button' onClick={onCloseHandler}>
            <Icon name='deleteSign' size='16' color='#025A66' />
          </button>
        </div>
      </div>
      <div className={expanded ? classes.content : classes.hide_content}>
        {children}
      </div>
    </div>
  )
}

export default ExpandableSection