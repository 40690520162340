import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_INTRODUCTION_PAGE } from "../graphql/queries"
import {
  START_PRACTICE_ASSESSMENT,
  SUBMIT_PRACTICE_ASSESSMENT,
  SUBMIT_ASSESSMENT,
  START_ASSESSMENT,
  CANCEL_ASSESSMENT,
  SUBMIT_COMMENT
} from '../graphql/mutations'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom"
import { datadogLogs } from '@datadog/browser-logs';

const useAssessment = () => {
  const [fetchIntroPage] = useLazyQuery(GET_INTRODUCTION_PAGE)
  const [createPracticeTest] = useMutation(START_PRACTICE_ASSESSMENT)
  const [createAssessment] = useMutation(START_ASSESSMENT)
  const [submitPracticeTestResponse] = useMutation(SUBMIT_PRACTICE_ASSESSMENT)
  const [submitAssessmentResponse] = useMutation(SUBMIT_ASSESSMENT)
  const [termicateAssessment] = useMutation(CANCEL_ASSESSMENT)
  const [commentAssessment] = useMutation(SUBMIT_COMMENT)
  const navigate = useNavigate()

  const getIntroPage = (page: number, setIntroPageData: React.Dispatch<React.SetStateAction<IAssessmentIntroduction | undefined>>) => {
    let logDetails: Record<string,any> = {
      pageNumber: page
    };
    datadogLogs.logger.info("Fetching assessment intro page...", logDetails);
    
    fetchIntroPage({
      variables: {
        pageNumber: page
      }
    }).then((response) => {
      datadogLogs.logger.info("Assessment intro page fetched successfully.");
      console.log(response.data.assessmentIntroduction)
      setIntroPageData(response.data.assessmentIntroduction)
    })
  }

  const startPracticeTest = ({setAssessmentData, setError}: ISubmitResponseData) => {
    datadogLogs.logger.info("Starting practice test...");
    
    createPracticeTest().then((response) => {
      datadogLogs.logger.info("Practice test started successfully");
      setAssessmentData(response.data.createPracticeTest)
    })
    .catch((errors) => {
      console.error('Error Starting Practice Test:', errors.graphQLErrors.map((error: any)=> error.message))
      setError && setError(errors.graphQLErrors[0].message)
    })
  }

  const submitPracticeTest = ({attributes, setAssessmentData, setError}: ISubmitResponseData) => {
    datadogLogs.logger.info("Submitting practice test...");
    
    submitPracticeTestResponse({
      variables: {
        attributes: attributes
      }
    })
    .then((response) => {
      datadogLogs.logger.info("Practice test submitted successfully");
      setAssessmentData(response.data.submitPracticeTest)
    })
    .catch((errors) => {
      console.error('Error Submiting Practice Test:', errors.graphQLErrors.map((error: any)=> error.message))
      setError && setError(errors.graphQLErrors[0].message)
    })
  }

  const startAssessment = ({setAssessmentData, setError}: ISubmitResponseData) => {
    datadogLogs.logger.info("Starting assessment...");
    
    createAssessment().then((response) => {
      datadogLogs.logger.info("Assessment started successfully");
      setAssessmentData(response.data.createAssessment)
    })
    .catch((errors) => {
      console.error('Error Starting Assessment:', errors.graphQLErrors.map((error: any)=> error.message))
      setError && setError(errors.graphQLErrors[0].message)
    })
  }
  
  const cancelAssessment = ({setStatus, setError}: ICancelAssessmentData) => {
    datadogLogs.logger.info("Terminating assessment...");
    
    termicateAssessment().then((response) => {
      datadogLogs.logger.info("Assessment terminated successfully");
      setStatus(response.data.cancelAssessment)
    })
    .catch((errors) => {
      console.error('Error Starting Assessment:', errors.graphQLErrors.map((error: any)=> error.message))
      setError && setError(errors.graphQLErrors[0].message)
    })
  }

  const submitAssessment = ({attributes, setAssessmentData, setError}: ISubmitResponseData) => {
    datadogLogs.logger.info("Submitting assessment...");
    
    submitAssessmentResponse({
      variables: {
        attributes: attributes
      }
    })
    .then((response) => {
      datadogLogs.logger.info("Assessment submitted successfully");
      setAssessmentData(response.data.submitAssessment)
    })
    .catch((errors) => {
      console.error('Error Submiting Assessment:', errors.graphQLErrors.map((error: any)=> error.message))
      setError && setError(errors.graphQLErrors[0].message)
    })
  }

  const submitComment = (comment: string) => {
    datadogLogs.logger.info("Submitting comment...");
    
    commentAssessment({
      variables: {
        comment: comment
      }
    }).then((response) => {
      datadogLogs.logger.info("Comment submitted successfully.");
      const submitCommentStatus = response.data.commentAssessment
      navigate('/')
      toast.info(submitCommentStatus.message)
    })
  }

  return { getIntroPage, startPracticeTest, submitPracticeTest, startAssessment, submitAssessment, cancelAssessment, submitComment }
}

export default useAssessment
