import React, { useState, useEffect } from 'react'
import InformationSection from "../InformationSection"
import classes from '../Sections.module.scss'
import ExpandableSection from "../ExpandableSection"
import InputField from "../../../shared/ComponentLibrary/RInputField"
import SelectInputField from "../../../shared/ComponentLibrary/RSelectInputField"
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form'
import Button from "../../../shared/ComponentLibrary/RButton"
import useOptions from '../../../../customHooks/useOptions'
import useProfile from '../../../../customHooks/useProfile'

type ProjectProps = {
  index: number
  project: UserProject
  register: any
  practiceAreaListData: PracticeAreaList
  industryListData: IndustryList
  softwareProductListData: SoftwareProductList
  responsibilityListData: ResponsibilityList
  languageListData: LanguageList
  expand: boolean
  onCloseHandler: () => void
}

interface IDataToSubmit {
  projects: ProjectData[]
  noPriorExperience: boolean
}

const Projects = ({ profileData, setProfileData, setDirtyForm }: ProfileTabsProps) => {
  const projectsData: UserProject[] = profileData.projects
  const { getPracticeAreaList, getIndustryList, getSoftwareProductList, getResponsibilityList, getLanguageList } = useOptions()

  const [practiceAreaListData, setPracticeAreaListData] = useState<PracticeAreaList>({})
  const [industryListData, setIndustryListData] = useState<IndustryList>({})
  const [softwareProductListData, setSoftwareProductListData] = useState<SoftwareProductList>({})
  const [responsibilityListData, setResponsibilityListData] = useState<ResponsibilityList>({})
  const [languageListData, setLanguageListData] = useState<LanguageList>({})
  
  const { register, handleSubmit, reset, control, formState: { isDirty } } = useForm({
    defaultValues: {
      projects: [...profileData.projects],
      noPriorExperience: profileData.noPriorExperience
    }
  })
  const { fields, append, remove } = useFieldArray({ control, name: "projects" })

  useEffect(() => { reset({projects: [...projectsData]}) }, [projectsData])

  
  const { updateUserProject } = useProfile()
  const [error, setError] = useState('')

  useEffect(() => setDirtyForm(isDirty), [isDirty])
  useEffect(() => {
    getPracticeAreaList(setPracticeAreaListData)
    getIndustryList(setIndustryListData)
    getSoftwareProductList(setSoftwareProductListData)
    getLanguageList(setLanguageListData)
    getResponsibilityList(setResponsibilityListData)
  }, [])

  const updateProfile: SubmitHandler<IDataToSubmit> = (data) => {
    setDirtyForm(false)
    let projects = data.projects
    projects = projects.map((project) => {
      delete project.practiceArea
      delete project.industry
      delete project.softwareProduct
      delete project.languages
      project.durationMonths = project.durationMonths.toString()
      return project
    })

    let updatedProfileData: IProjectData = {
      attributes: {
        projects: projects,
        noPriorExperience: projects.length > 0 ? false : data?.noPriorExperience
      },
      setProfileData: setProfileData,
      setError: setError
    }

    console.log(updatedProfileData)
    console.log('data', data)

    updateUserProject(updatedProfileData)
  }

  const AddProjectHandler = () => {
    const newProject: UserProject = {
      id: null,
      durationMonths: 0,
      responsibilities: [],
      practiceArea: {name: "", value: ""},
      industry: {name: "", value: ""},
      languages: [languageListData?.languageList?.find(lang => lang.name === 'English') as DropDownOption], // Set default language to English
      softwareProduct: softwareProductListData?.softwareProductList?.find(lang => lang.name === 'Relativity') as DropDownOption
    }

    append(newProject)
  }

  const onCloseHandler = (id: number) => remove(id)

  return (
    <div className={classes.section}>
      <form className={classes.form} onSubmit={handleSubmit(updateProfile)} >
        <InformationSection title="Document Review Projects">
          <label className={classes.label}>
            Reviewers who include details on at least five document review projects are four times more likely to be placed. Most reviewers include information on their top 10 projects or more (in terms of length or number of project responsibilities).
          </label>
          {
            fields && fields.map((project, index) => {
              return(
                <Project
                  key={index}
                  index={index}
                  register={register}
                  practiceAreaListData={practiceAreaListData}
                  industryListData={industryListData}
                  softwareProductListData={softwareProductListData}
                  responsibilityListData={responsibilityListData}
                  languageListData={languageListData}
                  project={project}
                  expand={(project.industry && project.industry.name) === '' && (project.practiceArea && project.practiceArea.name) === '' ? true : false}
                  onCloseHandler={() => onCloseHandler(index)}
                />
              )
            })
          }

          <div className={classes.add_button}>
            <Button type='button' size='small' privacy='allow' onClickMethod={AddProjectHandler} innerText='+ Add Project' variant='outlined' />
          </div>
        </InformationSection>

        <div className={classes.checkbox_field} hidden={fields.length > 0}>
          <input
            type="checkbox"
            defaultChecked={profileData?.noPriorExperience}
            data-dd-privacy="allow"
            {...register('noPriorExperience')}
          />
          <span>No prior document review experience.</span>
        </div>

        { error && <div className='text-danger'>{ error }</div> }
        <div className={classes.submit_button}>
          <Button type='submit' size='large' innerText='Update Profile' />
        </div>
      </form>
    </div>
  )
}

const Project = ({index, project, register, practiceAreaListData, industryListData, softwareProductListData, responsibilityListData, languageListData, expand, onCloseHandler}: ProjectProps) => {
  var currentIndustry: DropDownOption
  currentIndustry = industryListData.industryList?.find(({ value }) => value == (project.industry && project.industry.value !== '' ? project.industry.value : project.industryId)) as DropDownOption
  var currentPracticeArea: DropDownOption
  currentPracticeArea = practiceAreaListData.practiceAreasList?.find(({ value }) => value == (project.practiceArea && project.practiceArea.value !== '' ? project.practiceArea.value : project.practiceAreaId)) as DropDownOption

  return (
    <ExpandableSection heading={`Project ${index+1}: ${currentPracticeArea ? currentPracticeArea.name + ' - ' : ''} ${currentIndustry ? currentIndustry.name : ''}`} onCloseHandler={onCloseHandler} expand={expand}>
      {
        project.id && (
          <div className={classes.input_field}>
            <InputField
              name='id'
              defaultValue={project.id}
              hidden={true}
              register={{...register(`projects[${index}][id]`)}}
            />
          </div>
        )
      }

      <div className={classes.side_by_side}>
        <div className={classes.input_field}>
          {
            practiceAreaListData.practiceAreasList &&
            <SelectInputField
              name='Practice Area'
              label='Practice Area'
              value={project.practiceArea && project.practiceArea.value}
              privacy='allow'
              size='small'
              register={{...register(`projects[${index}][practiceAreaId]`)}}
              itemsList={practiceAreaListData.practiceAreasList}
            />
          }
        </div>
          
        <div className={classes.input_field}>
          {
            industryListData.industryList &&
            <SelectInputField
              name='Industry'
              label='Industry'
              value={project.industry && project.industry.value}
              privacy='allow'
              size='small'
              register={{...register(`projects[${index}][industryId]`)}}
              itemsList={industryListData.industryList}
            />
          }
        </div>
      </div>
      <div className={classes.side_by_side}>
        <div className={classes.input_field}>
          <InputField
            name='major'
            label='Duration (months)'
            defaultValue={project.durationMonths}
            privacy='allow'
            register={{...register(`projects[${index}][durationMonths]`)}}
            size='small'
          />
        </div>
        <div className={classes.input_field}>
          {
            softwareProductListData.softwareProductList &&
            <SelectInputField
              name='Review Software'
              label='Review Software'
              value={project.softwareProduct && project.softwareProduct.value}
              privacy='allow'
              size='small'
              register={{...register(`projects[${index}][softwareProductId]`)}}
              itemsList={softwareProductListData.softwareProductList}
            />
          }
        </div>
      </div>
      <div className={classes.side_by_side}>
        <div className={classes.input_field}>
          {
            responsibilityListData.responsibilityList &&
            <SelectInputField
              name='Project Responsibilities'
              label='Project Responsibilities'
              value={project?.responsibilities}
              privacy='allow'
              size='small'
              register={{...register(`projects[${index}][responsibilities]`)}}
              itemsList={responsibilityListData.responsibilityList}
              multiple
              withCheckboxes
            />
          }
        </div>
        <div className={classes.input_field}>
          {
            languageListData.languageList &&
            <SelectInputField
              label='Project Languages'
              name='Languages'
              register={{...register(`projects[${index}][languageIds]`)}}
              privacy='allow'
              size="small"
              value={project?.languages?.map((l) => l.value)}
              itemsList={languageListData.languageList}
              multiple
              withCheckboxes
            />
          }
        </div>
      </div>
    </ExpandableSection>
  )
}


export default Projects